"use strict";

define([
 'jquery',
 'underscore',
 'text-loader!common/misc/login.tpl'
], function($, _, html)
{
  let dialog, inputPassVisible, showPassButton, inputPass
  
  return {
    //////////////////////////////////////////////
    init: function()
    {
      var dialogEl = $(_.template(html)());
      $("body").after(dialogEl);
      
      dialog = $(document.getElementById('login-dialog'));

      showPassButton    = dialog.find('#showpass');
      inputPassVisible  = dialog.find("#password_show");
      inputPass         = dialog.find("#password");

      inputPassVisible.addClass('hidden');

      showPassButton.mousedown( function () 
      {
        inputPassVisible.val(inputPass.val());

        inputPassVisible.removeClass('hidden');
        inputPass.addClass('hidden');
      });
                                   
      showPassButton.mouseup( function () {
        inputPassVisible.addClass('hidden');
        inputPass.removeClass('hidden');
      });

    },
    
    askCredentials: function(cb)
    {
      dialog.modal({
        keyboard: false,
        backdrop: "static",
      });

      var loginInputEl    = dialog.find("#login");
      var passwordInputEl = dialog.find("#password");

      inputPassVisible.addClass('hidden');
      loginInputEl.val("");
      passwordInputEl.val("");
      
      var callback = () => cb(loginInputEl.val(), passwordInputEl.val());
      var throttled = _.throttle(callback, 2000);
      
      dialog.find("form").on("submit", ev => {
        ev.preventDefault();
        if (loginInputEl.val() && passwordInputEl.val()) throttled();
      });

      dialog.modal("show");
      loginInputEl.focus();
    },
    
    ////////////////////////////////////////////
    askToken: function(login,password)
    {
      const s = login.split("/");
      login = s[0];
      return $.ajax({
        url: config.darsan.auth + "/token",
        data: {login: login, password: password, check_allowed: "billing.allowed"},
        method: "POST",
      }).then(rec =>
      {
        if (s.length>1)
        {
          rec.pretend = s[1];
        }
        
        return rec;
      });
    },

    ////////////////////////////////////////////
    showError: function(err)
    {
      var errorTextEl = $("#login-dialog").find(".error-text");
      errorTextEl.text(err);
      errorTextEl.removeClass('hidden');
    },

    ////////////////////////////////////////////
    promiseToken: function()
    {
      var me = this;
      var deferred = $.Deferred();

      me.askCredentials( (login, password) =>
      {
        me.askToken(login, password)
        .then( rec =>
        {
          $("#login-dialog").modal("hide");
          deferred.resolve(rec);
        })
        .fail( err =>
        {
          let msg = ""
          if (err.status) msg = "Ошибка " + err.status + ": " + err.responseText;
          if (err.responseText.match(/not found/)) msg = "Неправильный логин"; 
          if (err.responseText=="Invalid credentials") msg = "Неправильный пароль"; 
          if (err.responseText.match(/is disabled/)) msg = "Пользователь отключен"; 
          if (err.responseText.match(/is not allowed/)) msg = "Вход в биллинг запрещен"; 
          me.showError(msg);
        });
      });
    
      return deferred.promise();
    },
    
  };
});
