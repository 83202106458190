var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Select", {
        attrs: {
          label: "Режим голосового меню",
          size: 3,
          labelSize: 2,
          options: _vm.notifications,
          name: "mode",
          apiDomain: "mak",
          apiTopic: "phone",
          apiPath: `/iron/${_vm.$store.state.config.domain}/mode`
        },
        model: {
          value: _vm.mode,
          callback: function($$v) {
            _vm.mode = $$v
          },
          expression: "mode"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }