var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("top"),
      _vm._v(" "),
      _c("page"),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$store.state.ajaxCalls > 0,
            expression: "$store.state.ajaxCalls>0"
          }
        ],
        attrs: { id: "load-indicator" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }