<template>
<font class="mount"></font>
</template>

<script>
import * as nav from "navigation.js"
import {eventBus} from "main.js"
import {notifyError} from "common.js"

export default {
  name: "RadixModule",
  
  props: {
    path: String,
    state: Object,
  },
  
  data() {
    return {
      module: null,
    }
  },
  
  created()
  {
    this.$on("call", ({method, args}) => 
    {
      const fun = this.module[method]
      if (fun && typeof fun == "function")
      {
        this.module[method].apply(this.module, args)
      }
      else
      {
        notifyError(`RadixModule: в модуле ${this.module.name} нет функции ${method}`)
      }
    })
  },
  
  mounted()
  {
    const mountPoint = this.$el

    nav.promiseModule(this.path, mountPoint, {})
    .then(mod =>
    {
      this.module = mod
      this.module.setState(Object.assign({}, this.state))
    })
    .catch(err => {
      notifyError("RadixModule: "+err)
      console.log(err)
    })
  },
  
  watch: {
    state(val)
    {
      if (this.module) this.module.setState(Object.assign ({}, val))
    },
  },
}
</script>
