var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "navbar navbar-inverse navbar-fixed-top main",
      attrs: { role: "navigation" }
    },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "navbar-header" }, [
          _c("a", { staticClass: "navbar-brand", attrs: { href: "#" } }, [
            _c("img", { attrs: { src: _vm.logoUrl } })
          ]),
          _vm._v(" "),
          _vm._m(0)
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "collapse",
            staticClass: "collapse navbar-collapse",
            attrs: { id: "mob-nav-menu" }
          },
          [
            _c("top-menu", {
              on: { menuSubitemClick: _vm.onMenuSubitemClick }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.rtt !== null,
                    expression: "rtt!==null"
                  }
                ],
                staticClass: "navbar-text",
                staticStyle: { "margin-left": "10%" }
              },
              [
                _vm.rtt > 0
                  ? _c(
                      "div",
                      {
                        staticStyle: { color: "darkslategray" },
                        attrs: { title: "Время запроса к серверу" }
                      },
                      [_vm._v(_vm._s(_vm.rtt) + " мс")]
                    )
                  : _c(
                      "div",
                      {
                        staticStyle: { color: "red" },
                        attrs: { title: "Нет соединения" }
                      },
                      [_c("Icon", { attrs: { name: "fa-unlink" } })],
                      1
                    )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$store.state.linkUid != null,
                    expression: "$store.state.linkUid!=null"
                  }
                ],
                staticClass: "navbar-text"
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "larger" },
                    style: { opacity: _vm.linkIconOpacity },
                    attrs: { title: "Звонок от абонента" },
                    on: { click: _vm.openClient }
                  },
                  [_c("Icon", { attrs: { name: "user" } })],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "navbar-right",
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "margin-right": "15px"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "btn-group",
                    staticStyle: { "margin-left": "15px" }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default dropdown-toggle",
                        staticStyle: {
                          "border-color": "transparent",
                          background: "transparent",
                          height: "46px",
                          padding: "0px",
                          "box-shadow": "none"
                        },
                        attrs: {
                          type: "button",
                          "data-toggle": "dropdown",
                          "aria-expanded": "false"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "img-avatar img-circle",
                          staticStyle: { height: "35px", width: "35px" },
                          attrs: { title: _vm.avatarTitle, src: _vm.avatarUrl }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "dropdown-menu", attrs: { role: "menu" } },
                      [
                        _c("li", { staticClass: "user-header text-center" }, [
                          _c("img", {
                            staticClass: "img-avatar img-circle",
                            staticStyle: { height: "75px", width: "75px" },
                            attrs: { alt: "Аватар", src: _vm.avatarUrl }
                          }),
                          _vm._v(" "),
                          _c("p", { attrs: { id: "profile-menu-title" } }, [
                            _c("b", [
                              _vm._v(_vm._s(this.$store.state.user.cn))
                            ]),
                            _c("br"),
                            _vm._v(
                              _vm._s(this.$store.state.user.login) +
                                "\n              "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._m(1),
                        _vm._v(" "),
                        _vm._m(2)
                      ]
                    )
                  ]
                ),
                _vm._v("\n\n         \n      \t"),
                _c("form", { attrs: { onsubmit: "return false;" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchMask,
                        expression: "searchMask"
                      }
                    ],
                    staticClass: "form-control top-menu-client-search",
                    attrs: { type: "text", placeholder: "Поиск абонентов" },
                    domProps: { value: _vm.searchMask },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.startSearch.apply(null, arguments)
                      },
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.searchMask = $event.target.value
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-left": "15px",
                      "font-size": "18px",
                      color: "dodgerblue"
                    },
                    attrs: { id: "logout" }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-sign-out fa-lg",
                      staticStyle: { cursor: "pointer" },
                      attrs: { title: "Выйти" },
                      on: { click: _vm.logout }
                    })
                  ]
                )
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggle collapsed",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#mob-nav-menu",
          "aria-expanded": "false",
          "aria-controls": "mob-nav-menu"
        }
      },
      [
        _c("span", { staticClass: "icon-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" })
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "text-center" }, [
      _c("a", { attrs: { id: "profile-link", href: "/me" } }, [
        _vm._v("Профиль")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "text-center" }, [
      _c("a", { attrs: { href: "/profile" } }, [_vm._v("Настройки")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }