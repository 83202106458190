<template>

  <div>
    <SearchTable v-if="ready" ref=table name="monitTree" title="Деревья" :columns=columns dynamic 
     apiTopic=device apiPath="/monit/tree?paged=1" 
     metaApiTopic="device" metaApiPath="/meta/monit/tree/search"
     :rowClass=rowClass
    >
      
      <template #device_comment="{rec}">
        {{ rec.device_comment }}
      </template>

      <template #tree="{rec}">
        {{ rec.tree }}
      </template>

    </SearchTable>

    <SkelSearchTable v-else />

  </div>
</template>
  
<script>
  import Vue from "vue/dist/vue.esm.js"

  import darsan from "darsan"
  import SkelSearchTable from "common/visual/SkelSearchTable.vue"
  import { eventBus } from "main"
  import { goto } from "navigation"

  export default {
    name: "PonTreePage",
    
    components: { SkelSearchTable },
  
    props: {
      state: Object,
    },
    
    data () {
      return {
        ready: false,
        columns: [
          {
            name: "device_comment",
            title: "Название",
            sort: true,
          },
          {
            name: "tree",
            title: "Дерево",
            sort: "true",
          },
          {
            name: "onu_count",
            title: "Количество ONU",
            sort: true,
          },
        ],
        
      }
    },
    
    created () {
      this.ready = true
    },
    
    activated () {
      if (this.$refs.table) this.$refs.table.$emit("reloadTable")
    },
    
    methods: {
      rowClass (rec) {
        if (rec.disable) return "warning"
      },
    },
  }
</script>
