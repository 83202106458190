<template>
<div>
  <Select label="Режим голосового меню" :size=3 :labelSize=2 :options=notifications v-model=mode name="mode" apiDomain="mak" apiTopic="phone" :apiPath="`/iron/${$store.state.config.domain}/mode`" />
</div>
</template>

<script>
import Select from "common/visual/cooper/Select.vue"
import darsan from "darsan"

export default {
  name: "IronModePage",
  
  components: {Select},
  
  data()
  {
    return {
      mode: "",
      notifications: [],
    }
  },
  
  async created()
  {
    const res = await darsan.get("mak", "phone", "/notification?type=crash")
    this.notifications = res.map(el => ({text: el.name_ru, value: el.name}))
    this.notifications.unshift({value: "", text: "Обычный режим"})

    const res2 = await darsan.get("mak", "phone", `/iron/${this.$store.state.config.domain}/mode`)
    this.mode = res2.mode
  }
}
</script>

<style>
</style>
