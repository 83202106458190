<template>
<div>
  <top/>
  <page/>
  <div id="load-indicator" v-show="$store.state.ajaxCalls>0"></div>
</div>  
</template>

<script>

import Top from "Top.vue"
import Page from "Page.vue"
import $ from "jquery"

export default {
  name: "app",
  components: {Top, Page},
  
  created()
  {
    $(document).on("ajaxStart", () => this.$store.commit("addAjaxCall"))
    $(document).on("ajaxStop", () => this.$store.commit("delAjaxCall"))
  },
  
  mounted()
  {
    $(document).on("keydown", ev => 
    {
      if (ev.keyCode==17)
      {
        if (this.$store.state.ctrlPressed) return;
        this.$store.commit("setCtrlPressed", true)
      }
    })

    $(document).on("keyup", ev => 
    {
      if (ev.keyCode==17)
      {
        this.$store.commit("setCtrlPressed", false)
      }
    })

  },
  
  methods: {
  },
}
</script>

<style>
</style>