<template>
  <keep-alive>
    <component :is=currentComponent :state=state />
  </keep-alive>
</template>

<script>
  import LoopLogTable  from 'loop-log/page/LoopLogTable.vue'

  import { eventBus } from 'main'

  export default {
    name: 'LoopLogPage',
    title: 'Петли на устройствах',
    components: {},
    
    data () {
      return {
        currentComponent: null,
        state: {
          _rest: "",
          _tail: "",
          subpath: "",
          id: "",
        },
      }
    },
    
    created () {
      eventBus.on('routeChanged', ({ path, params }) => this.setState(path, params))
    },
    
    methods: {
      setState(path, params) {
        const state = {}
        this.currentComponent = LoopLogTable
        this.state = state
      },

    },
  }
</script>
