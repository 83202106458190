<template>
<nav class="navbar navbar-inverse navbar-fixed-top main" role="navigation">
  <div class="container-fluid">
    
    <div class="navbar-header ">
      <a class="navbar-brand" href="#">
        <img :src=logoUrl />
      </a>
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#mob-nav-menu" aria-expanded="false" aria-controls="mob-nav-menu">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>

    <div class="collapse navbar-collapse" id="mob-nav-menu" ref="collapse">
      <top-menu @menuSubitemClick="onMenuSubitemClick"/>
      
      <div class="navbar-text" style="margin-left: 10%" v-show="rtt!==null">
        <div v-if="rtt>0" title="Время запроса к серверу" style="color: darkslategray">{{rtt}} мс</div>
        <div v-else style="color: red" title="Нет соединения"><Icon name=fa-unlink /></div>
      </div>
      
      <div class="navbar-text" v-show="$store.state.linkUid!=null" >
        <span title="Звонок от абонента" v-bind:style="{ opacity: linkIconOpacity }" style="font-size: larger" @click=openClient ><Icon name="user" /></span>
      </div>
      
      <div class="navbar-right" style="display: flex; align-items: center; margin-right: 15px;">

        <div class="btn-group" style="margin-left:15px;">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false" style="border-color:transparent; background:transparent; height:46px; padding:0px; box-shadow: none;">
            <img class="img-avatar img-circle" style="height:35px; width:35px;" :title=avatarTitle :src=avatarUrl />
          </button>
          <ul class="dropdown-menu" role="menu">
            <li class="user-header text-center">
              <img class="img-avatar img-circle" alt="Аватар" style="height: 75px;width: 75px;" :src=avatarUrl />
              <p id="profile-menu-title">
                <b>{{this.$store.state.user.cn}}</b><br/>{{this.$store.state.user.login}}
              </p>
            </li>
            <li class="text-center"><a id="profile-link" href="/me">Профиль</a></li>
            <li class="text-center"><a href="/profile">Настройки</a></li>

          </ul>
        </div>

        &nbsp;
      	<form onsubmit="return false;">
	        <input class="form-control top-menu-client-search" type="text" placeholder="Поиск абонентов" v-model="searchMask" @keyup.enter=startSearch />
       	</form>

      	<span id="logout" style="margin-left:15px;font-size:18px; color: dodgerblue">
        	<i class="fa fa-sign-out fa-lg " style="cursor: pointer;" title="Выйти" @click=logout></i>
      	</span>
      </div>

    </div>

  </div>

</nav>

</template>

<script>

import darsan from "darsan"
import {goto} from "navigation"

import TopMenu from "TopMenu.vue"

export default {
  name: "top",
  
  components: { TopMenu },
  
  data()
  {
    return {
      searchMask: "",
      rtt: null,
    }
  },
  
  created()
  {
    if (navigator.connection)
    {
      this.rtt = navigator.connection.rtt
      navigator.connection.onchange = () => this.rtt = navigator.connection.rtt
    }
  },

  methods: {
    logout()
    {
      darsan.removeToken();
      location.reload();
    },
    
    startSearch()
    {
      goto("/search?mask=" + this.searchMask, {redirect: true})
      this.searchMask = ""

      $(this.$refs.collapse).collapse('hide')
    },

    onMenuSubitemClick(item) {
      $(this.$refs.collapse).collapse('hide')
    },
    
    openClient()
    {
      const uid = this.$store.state.linkUid
      if (uid) window.open(`/client/${uid}`,"_blank")
    },
  },
  
  computed: {
  
    logoUrl() { return "/img/logo/logo-" + this.$store.state.config.domain + "-small.png"},

    avatarTitle() 
    { return this.$store.state.user.pretend ? 
         this.$store.state.user.cn + " как " + this.$store.state.user.pretend 
         : this.$store.state.user.cn 
    },

    avatarUrl() 
    {
      return API + '/avatar/' + (this.$store.state.user.pretend || this.$store.state.user.login);
    },
    
    linkIconOpacity()
    {
      return this.$store.state.linkUid ? 1 : 0.3
    },
   
  },
}
</script>

<style>
</style>
