// Предварительная загрузка всяких данных с удаленных серверов Дарсана в виде коллекций
define([
  'underscore',
  'common',
  'darsan',
  'backboneRadix',
], function(_, common, darsan, Backbone)
{
  "use strict";

/////////////////////////////////////////////////

  const _urlTable = {
        "action": ["client", "/action"],
        "bundle": ["client", "/bundle"],
        "client-tags": ["client", "/client-tag"],
        "client-tags-connection-type": ["client", "/client-tag/ConnectionType"],
        "currency": ["client", "/meta/currency"],
        "device": ["device", "/device"],
        "device-groups": ["device", "/meta/device-groups"],
        "device-nodes": ["device", "/node"],
        "device-kinds": ["device", "/meta/device/kind"],
        "device-tags": ["device", "/device-tag"],
        "entities": ["client", "/meta/entity"],
        "fop": ["client", "/fop"],
        "group": ["client", "/group"],
        "group-type-building": ["client", "/meta/group/type-building"],
        "ippools": ["client", "/meta/ippools"],
        "iptv-channel": ["iptv", "/iptv/channel"],
        "legal-types": ["client", "/meta/legal-types"],
        "messages-templates": ["client", "/config/messages-templates?query=type%3D%22template%22"],
        "nas": ["client", "/nas-names"],
        "nas-ippools": ["client", "/meta/nas/ippools"],
        "nas-types": ["client", "/meta/nas-types"],
        "news-tags": ["client", "/meta/news/tags"],
        "news-templates": ["client", "/news/template"],
        "notifications-templates": ["phone", "/notification"],
        "news-notifications-templates": ["phone", "/notification"],
        "payment-method": ["client", "/meta/payment-method"],
        "permission-tags": ["darsan", "/permission-tag"],
        "pontree": ["device", "/pontree"],
        "quality-params": ["client", "/meta/quality-param"],
        "reduction-method": ["client", "/meta/reduction-method"],
        "script-for-clients": ["client", "/script-for-clients/"],
        "script-start-cond": ["client", "/script-start-cond/"],
        "shift-type": ["darsan", "/shift_type"],
        "simple-service-type": ["client", "/meta/simple-service-type"],
        "static-pools": ["client", "/meta/static-ippool/base"],
        "store-class": ["store", "/class"],
        "store-places": ["store", "/place"],
        "store-places-types": ["store", "/place/meta/types"],
        "store-tags": ["store", "/tag"],
        "store-tags-types": ["store", "/tag/meta/types"],
        "task-attr": ["task", "/tag"],
        "task-attr-modes": ["task", '/meta/task_attr/modes'],
        "task-attr-types": ["task", '/meta/task_attr/types'],
        "task-defect": ["task", "/meta/defect"],
        "task-list": ["task", "/meta/list"],
        "task-state": ["task", "/meta/state"],
        "task-tags": ["task", "/meta/tags"],
        "task-types": ["task", "/meta/task-types"],
        "eset-currency": ["client", "/eset/currency"],
        "eset-products": ["client", "/eset/product"],
        "eset-customer-type": ["client", "/eset/customer-type"],
        "tplan": ["client", "/tariff-plan"],
        "tplan?internet": ["client", "/tariff-plan?type=home&type=business&type=free&type=personal"],
        "tplan?megogo": ["client", "/meta/megogo/tariff"],
        "tplan?omegatv": ["client", "/meta/omegatv/tariff"],
        "tplan?static-ip": ["client", "/tariff-plan?type=static-ip"],
        "tplan?tv": ["client", "/tariff-plan?type=tv"],
        "tplan-types": ["client", "/meta/tplan-types"],
        "vlan-groups": ["device", "/meta/vlan-groups"],
        "worker": ["darsan", "/worker"],
        "worker-for-month": ["darsan", "/worker-for-month"],
  }
  
  const _extraTable = {
      "notifications-templates": function(prefix, name)
      {
        var main = this.get(prefix, name);

        var r = [];
        main.map( m => { if (m && m.get('type') != 1) r.push(m) })
        main.remove(r);
      },
      "currency": function(prefix, name)
      {
        if (!("main-currency" in this.loaded[prefix])) this.loaded[prefix]["main-currency"] = {};

        var main = this.get("", name).findWhere({main: 1});
        if (!main) return;

        this.loaded[prefix]["main-currency"] = main.get("short_name_ru")
      },
  }
  
///////////////////////////////////////////////////////////  

  const promises = {}

  return {

    loaded: {},

    ensure: function(...args)
    {
      var me = this;
      var plist = [];
      
      const prefixes = config.additional_client_domains.map(el => el.name)
      prefixes.unshift("")

      for (const name of args)
      {
        for (const prefix of prefixes)
        {
          if (!promises[prefix+name]) 
          {
            promises[prefix+name] = me._makePromise(prefix, name)
          }
          plist.push(promises[prefix+name])
        }
      }

      return common.when(plist)
    },

    get: function(prefix, name)
    {
      if (prefix==config.domain) prefix = ""
      return this.loaded[prefix][name]
    },

    _makePromise: function(prefix, name)
    {
        var me = this;

        if (!me.loaded[prefix]) me.loaded[prefix] = {}
        var coll = me.loaded[prefix][name] = new Backbone.EntityCollection();

        var todo = me._toDo(prefix, name);
        coll.url = todo.url;

        return coll.fetchm({reset: true})
          .then(
            (coll, list) => 
            {
              return [prefix, name, list]
            },
            function(coll, xhr)
            {
              if (xhr.status != 403) darsan.err(xhr)
              return common.okPromise([]);
            })
          .done( (coll, list) =>
          {
            if (todo.done) todo.done.call(me, prefix, name);
          })
    },
    
    _toDo: function(prefix, name)
    {
      var me = this;
      
      const url = _urlTable[name]
      if (!url) throw "preload: неизвестный запрос " + name;
      
      if (prefix=="") prefix = config.domain
      const rec = { url: darsan.makeURL(prefix, url[0], url[1]) }
      
      const f = _extraTable[name]
      if (f) rec.done = f
      
      return rec
    },
    
    preloadList()
    {
      const prefixes = config.additional_client_domains.map(el => el.name)
      prefixes.unshift("")
      
      const res = []
      
      for (const p of prefixes)
      {
        for (const n of Object.keys(_urlTable))
        {
          res.push(`${p}/${n}`)
        }
      }
      
      return res
    },
  };
});
