import * as common from "common"
import moment from "moment"

export default {

  data()
  { 
    return {
      common,
      moment,
    }
  },
    
  methods: {
    ensure(...args)
    {
      return this.$store.dispatch("preloadEnsure", ...args)
    },
  },
}
