import $ from "jquery"
import _ from "underscore"
import * as common from "common"
import Backbone from "backbone-ex"
import darsan from "darsan"
import {notifyError, findKeyWhere, okPromise} from "common"
import pageMixin from 'common/visual/page-mixin'
import sysconf from "sysconf"
import allowed from "allowed"
import {eventBus} from "main"
import {currentPage} from "Page.vue"

const availableVuePages = require.context("./", true, /.*\.vue$/).keys()

    let currentPageName = ""

    export function isPartialState(state)
    {
      return _.size(state) <= 2 && state._rest == "";
    }

    export function changePage(url, opt)
    {
      eventBus.emit("changePage", {url,opt})
    }

    export function addRoutes()
    {
      var me = this;

      var AppRouter = Backbone.Router.extend({
        routes: {
          "(/)":           "index",
          "page/:page":    "defaultStatePage",
          "*url": 'changePage',
        },

        changePage(url)
        {
          me.changePage(url+window.location.search)
        },

        defaultStatePage(page)
        {
          me.changePage("/"+page,{defState: true});
        },

        index() {
//          console.log('/index -> /news');
          Backbone.history.navigate("/news", true);
        }
      });

      new AppRouter();

      $(document).delegate("a:not([data-direct-link='true']):not([href^=javascript])", "click", function(evt) {
        var href = $(this).attr("href");
        if(href && href!='#' && !/:\/\//.test(href))
        {
          var protocol = this.protocol + "//";
          if (href.slice(protocol.length) !== protocol) {
            evt.preventDefault();
            Backbone.history.navigate(href, true);
          }
        }
      });
    }

    export function goto(url)
    {
      Backbone.history.navigate(url,{trigger: true});
    }

    export function gotoNewTab(url)
    {
      var win = window.open(url, '_blank');
      win.focus();
    }

    export function newState(oldState,vars)
    {
      var keys = _.chain(vars).keys().filter(function (k) { return _.isNull(vars[k]) }).value();
      var newState = _.extend({}, oldState, vars);
      _.each(keys, k => delete newState[k] );
      return newState;
    }

    export function changeStateURL(diff)
    {
      var ns = newState(currentPage.getState(), diff);
      return currentPage.stateToURL(ns);
    }

    export function changeState(vars)
    {
      if (currentPage.create)
      {
        goto(changeStateURL(vars))
      }
      else
      {
        // Страница vue
        const url = new URL(window.location)
        for (const name in vars)
        {
          vars[name]===null ? url.searchParams.delete(name) : url.searchParams.set(name, vars[name])
          goto(url.pathname+url.search)
        }
      }
    }

    // меняет УРЛ в браузере, но перехода на другую страницу не происходит
    // используется табами и тому подобным
    export function changeURL(diff)
    {
      //console.log("cURL"); patchURL();
      var ns = newState(currentPage.getState(), diff);
      currentPage.state = ns;
    }

    // Меняет один параметр в УРЛ в строке браузера
    // (без перехода на новую страницу)    
    export function changeURLParam(name, value)
    {
      const url = new URL(window.location)
      value===null ? url.searchParams.delete(name) : url.searchParams.set(name, value)
      Backbone.history.navigate(url.pathname+url.search, {trigger: false, replace: true});
    }

    // Меняет один параметр в УРЛ в строке браузера
    // с переходом на новую страницу
    export function changeURLSubpath(value)
    {
      const path = window.location.pathname
      const m = path.match(/^\/\w+\/[\w-$]+/)
      if (!m) return
      const newPath = m[0] + (value ? "/"+value : "")
      Backbone.history.navigate(newPath, {trigger: true, replace: false});
    }
    
    export function getURLParams(...params)
    {
       const url = new URL(window.location)
       return params.map(el => url.searchParams.get(el))
    }
    
    export function changePageState(vars)
    {
      currentPage.setState(newState(currentPage.getState(), vars));
    }
    
    export function promiseLoadModule(path)
    {
      if (path.substring(0,1)!="/") path = "/" + path
      
      if (availableVuePages.includes("."+path+".vue"))
      {
        return promiseLoadVuePage(path)
      }
      else
      {
        return promiseLoadOldModule(path)
      }
    }

    function promiseLoadOldModule(path)
    {
      return import(
        /* webpackMode: "lazy" */
        "."+path+".js")
        .then(mod => 
        {
          mod.default.loadPath = path
          return mod.default
        })
    }

    function promiseLoadVuePage(path)
    {
      return import(
        "."+path+".vue")
        .then(mod => 
        {
          mod.default.loadPath = path
          return mod.default
        })
    }

    export function promiseModule(path,place,options)
    {
      var me = this;
      return me.promiseLoadModule(path).then(mod => me.promiseCreateModule(mod,place,options))
    }

    // Возвращает модуль, инициализируя его при необходимости
    export function promiseCreateModule(mod,place,options)
    {
      if (mod.initialized) return $.when(mod);
      return $.when(mod.create(place,options)).then(function(data)
      {
        mod.initialized = true;
        return mod
      }).catch(err => 
      {
        common.notifyError(err);
        console.log(err)
      });
    }

    export function moduleFromPath(rec)
    {
      var me = this;
      if (rec.module) return common.okPromise();
      if (!rec.modPath) throw "moduleFromPath: no .module or .modPath";
      return promiseLoadModule("/"+rec.modPath).then((mod) => rec.module=mod);
    }

    export function setTitle(str)
    {
      document.title = str || config.provider;
    }
