var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.ready
        ? _c("SimpleTable", {
            ref: "table",
            attrs: {
              name: "loop-log",
              title: "Петли на устройствах",
              columns: _vm.columns,
              apiTopic: "device",
              apiPath: "/srv/loop-log",
              state: _vm.state.params
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "device_name",
                  fn: function({ rec }) {
                    return [
                      _c(
                        "a",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function($event) {
                              return _vm.showDevice(rec)
                            }
                          }
                        },
                        [_vm._v(_vm._s(rec.device_name))]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              2721813084
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }