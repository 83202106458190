var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "nav navbar-nav" },
    [
      _vm._l(this.$store.state.menu, function(item) {
        return [
          item.submenu && item.submenu.length
            ? [
                _c("li", { staticClass: "dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-toggle",
                      attrs: {
                        href: item.url || "",
                        "data-toggle": "dropdown",
                        role: "button",
                        "aria-expanded": "false"
                      }
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(item.label) }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "caret" })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "dropdown-menu", attrs: { role: "menu" } },
                    [
                      _vm._l(item.submenu, function(subitem, i) {
                        return [
                          subitem == "---"
                            ? [
                                i == 0 ||
                                i == item.submenu.length - 1 ||
                                !(item.submenu[i + 1] instanceof Object)
                                  ? void 0
                                  : _c("li", { staticClass: "divider" })
                              ]
                            : _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: subitem.url,
                                      target: /^http/.test(subitem.url)
                                        ? "_blank"
                                        : "_self"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.menuSubitemClick(subitem)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(subitem.label))]
                                )
                              ])
                        ]
                      })
                    ],
                    2
                  )
                ])
              ]
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }