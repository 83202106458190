define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common',
  'navigation',
  'permission',
  'allowed',
  'radio',
  'announcer',
  'sysconf',
  'login',
  'favico.js',
  'preload',

  'text-loader!common/misc/top-menu.tpl',

], function($, _, Backbone, darsan, common, nav, perm, addMod, radio, announcer, sysconf, login, Favico, preload, menuTemplate) {
  return {
  
    bootstrapThemes: {
      "default"   : "/css/bootstrap/bootstrap.min.css",
      "cerulean"  : "/css/bootstrap/cerulean/bootstrap.min.css",
      "cosmo"     : "/css/bootstrap/cosmo/bootstrap.min.css",
      "cyborg"    : "/css/bootstrap/cyborg/bootstrap.min.css",
      "darkly"    : "/css/bootstrap/darkly/bootstrap.min.css",
      "flatly"    : "/css/bootstrap/flatly/bootstrap.min.css",
      "journal"   : "/css/bootstrap/journal/bootstrap.min.css",
      "lumen"     : "/css/bootstrap/lumen/bootstrap.min.css",
      "paper"     : "/css/bootstrap/paper/bootstrap.min.css",
      "readable"  : "/css/bootstrap/readable/bootstrap.min.css",
      "sandstone" : "/css/bootstrap/sandstone/bootstrap.min.css",
      "simplex"   : "/css/bootstrap/simplex/bootstrap.min.css",
      "slate"     : "/css/bootstrap/slate/bootstrap.min.css",
      "spacelab"  : "/css/bootstrap/spacelab/bootstrap.min.css",
      "superhero" : "/css/bootstrap/superhero/bootstrap.min.css",
      "united"    : "/css/bootstrap/united/bootstrap.min.css",
      "yeti"      : "/css/bootstrap/yeti/bootstrap.min.css",
      "nael"      : "/css/bootstrap/nael/bootstrap.min.css",
    },

    changeBootstrapTheme: function(theme)
    {
      $("link#bootstrap-theme-stylesheet").attr("href",this.bootstrapThemes[theme]);
    },

    createHeader: function()
    {
      // 2.5) Listener 
      $("#client-global-search").submit(function( event ) 
      {
        var search_word = $(this).find("input").val();
        if (search_word)
        {
          nav.changePage("/client/search?mask=" + search_word, {redirect: true});
        }
        event.preventDefault();
      });
    },
    
    createAnnouncer: function(vue, url, login, worker)
    {
      var sessTrigger = msg => radio.channel("session").trigger(msg.topic, msg)
      // поллер поделен на много частей, запросы об обновлении приходят постоянно, ограничиваем их поток
      var pollTrigger = _.throttle(msg => radio.channel("poller").trigger(msg.topic, msg), 5*60*1000)
      var newsTrigger = msg => radio.channel("news").trigger(msg.topic, msg)
      var megogoTrigger = msg => radio.channel("megogo").trigger(msg.topic, msg)
      
      const ann = new announcer.default
      ann.open(url)
      ann.whenReady().then(() =>
      {
        ann.subscribe(["disconnect.*", "session.#", "poller.#", "news.#", "asterisk.exten.link", "asterisk.exten.unlink"], "radix-"+login)
        ann.listen({
          "disconnect.fail": msg => radio.channel("disconnect").trigger(msg.topic, msg),
          "poller.loop-syslog": pollTrigger,
          "poller.poll-completed": pollTrigger,
          "session.ppp.up": sessTrigger,
          "session.ppp.down": sessTrigger,
          "session.ipoe.up": sessTrigger,
          "session.ipoe.down": sessTrigger,
          "news.new": newsTrigger,
          "news.changed": newsTrigger,
          "news.delete": newsTrigger,

          "megogo.new": megogoTrigger,
          "megogo.change": megogoTrigger,
          "megogo.delete": megogoTrigger,
          
          "asterisk.exten.unlink": msg =>  vue.$store.commit("clearLinkUid"),
          
          "asterisk.exten.link": msg => common.dedup.send(`${msg.exten}/${msg.number}`, () => 
          {
            if (msg.outgoing || +msg.exten != +worker.sip) return

            darsan.get("", "client", `/srv/clients-by-phone?number=${msg.number}`)
              .done(list => 
              {
                vue.$store.commit("setLinkUid", list.length ? list[0].uid : "")
              })
          }),

        })
      })

      return ann
    },
    
    initDarsan: function(darsan)
    {
      darsan.userLogin = "radix";
      darsan.useSessionStorage = false;
      
      darsan.promiseToken = _.bind(login.promiseToken, login);

      darsan.map = config.darsan_map || {}

      if (!(darsan.map[config.domain] instanceof Object)) 
      {
        darsan.map[config.domain] = {}
      }
      
      darsan.map[ "" ] = darsan.map[config.domain];
    },

    // Здесь инициализация самого Радикса, не зависящая от пользователя
    // Вызывается один раз при загрузке.    
    initRadix: function(vue)
    {
      var me = this;
      // Создать верхнюю панель навигации      
      me.createHeader();
    
      // 5) создать объект роутов и добавить туда наши роуты
      nav.addRoutes();
      
      // 6) Повесить обработчик секретной клавиши
      var onCtrl = false;
      
      $(document).on('keydown', (ev) => {
        if (ev.keyCode !== 17) return;
        if (onCtrl) return;
        onCtrl = true;
        $(document).find("[data-on-secret-key]").show();
      });

      $(document).on('keyup', (ev) => {
        if (ev.keyCode !== 17) return;
        onCtrl = false;
        $(document).find("[data-on-secret-key]").hide();
      });
      
      // Инициализация store для preload
      for (name of preload.preloadList())
      {
        vue.$store.commit("initPreload", [name, []])
      }
      
    }, // initRadix

    // Здесь инициализация частей Радикса, которые зависят от конкретного пользователя
    // Вызывается после каждого успешного логина
    initUser: function(vue, rec)
    {
      var me = this;

      var loginChanged = user.login != rec.login;
      user.login = rec.login;
      user.cn = rec.cn;
      user.token = rec.token
      user.sip = rec.sip
      if (rec.pretend) user.pretend = rec.pretend;
      
      vue.$store.commit("setUser", user)

      favicon = new Favico({
        position: 'up'
      });
      
      $.when(
        darsan.get("", "darsan", "/worker/" + (user.pretend || user.login) + "/config/radix"),
        darsan.get("", "darsan", "/worker/" + (user.pretend || user.login)),
        perm.load(user.login, vue),
        addMod.load(),
        vue.$store.dispatch("loadEntitiesFrom", "client"),
      ).done( (settings, worker, permissions, allowedModules, loadEnt) =>
      {
        // отфильтровать вкладки клиента, на которые нет прав
        sysconf.uiClientTabs = addMod.filterLayout(sysconf.uiClientTabs);
        
        userSettings = settings = _.defaults(settings || {}, { rowsPerPage: 25, bootstrapTheme: 'default', infinite_scroll: true, notifyPhoneLink: true });
        vue.$store.commit("setUserSettings", userSettings)

        // 4) подогнать вид под настройки - стиль бутстрапа и все такое
        me.changeBootstrapTheme(settings.bootstrapTheme);

        // 2) слушать аннонсер
        announcers.forEach( ann => ann.close() )
        announcers = []
        let announcerList = Array.isArray(config.announcer) ? config.announcer : [ config.announcer ]
        announcerList.forEach( url => announcers.push(me.createAnnouncer(vue, url, user.login, worker)) )
        
        if (userSettings.loopDetectEverywhere && userSettings.loopDetectEverywhere == 1) 
        {
          radio.channel("poller").on('poller.loop-syslog', function(message) {
            common.notifyDeviceLoopDetect(message.text)
          })
        }

        // 8) перейти на вызванную страницу или если /, то на открытую пользователем в последний раз
        // (взята из настроек)
        
        if (loginChanged)
        {
          var page = location.pathname;
          var m;
          if (page === "/") {
            page = settings.lastOpened || "/client";
            Backbone.history.start({pushState: true, silent: true});
            Backbone.history.navigate(page, {trigger: true});
          } else if (m = page.match("^/page/(.+)")) {
            Backbone.history.start({pushState: true, silent: true});
            nav.changePage("/"+m[1],{redirect: true});
          } else {
            Backbone.history.start({pushState: true});
          }
        }
        
      });
    }, // initUser

  }; // init

});  // --
