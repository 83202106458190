var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Table", {
    attrs: {
      name: "notification-log",
      title: "Обзвон",
      columns: _vm.columns,
      "api-topic": "phone",
      "api-path": "/notification-log",
      fetch: "simple",
      rowClass: _vm.rowClass
    },
    scopedSlots: _vm._u([
      {
        key: "done",
        fn: function({ rec }) {
          return [rec.done == 1 ? [_vm._v("✓")] : void 0]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }