define([
    'underscore',
    'common',
], function(_, common)
{
    return {
        /////////////////////////
        domainByUid: function(uid)
        {
          const el = config.additional_client_domains.find(el => +el.range[0] <= +uid && +uid <= +el.range[1])
          return el ? el.name : ""
        },
    };
});
