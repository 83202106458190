<template>
  <Table name="notification-log" title="Обзвон" :columns=columns 
   api-topic="phone" api-path="/notification-log" fetch="simple"
   :rowClass=rowClass        
  >
    <template #done="{rec}">
      <template v-if="rec.done==1">&#x2713;</template>
      <template v-else></template>
    </template>

  </Table>
</template>

<script>
export default {
  name: "NotificationLog",
  
  data()
  {
    return {
      columns: [
          {
            name: "created",
            title: "Создан",
          },
          {
            name: "dst",
            title: "Номер",
          },
          {
            name: "message",
            title: "Сообщение",
          },
          {
            name: "channel",
            title: "Канал",
          },
          {
            name: "tries",
            title: "Попыток",
          },
          {
            name: "last_called",
            title: "Последняя попытка",
          },
          {
            name: "error",
            title: "Ошибка",
          },
          {
            name: "done",
            title: "Завершён",
          },
      ],
    }
  },
  
  methods: {
    rowClass(rec)
    {
      if (rec.error) return "danger"
      if (rec.done==0) return "success"
    }
  }
}
</script>