var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "l-map",
    {
      ref: "myMap",
      staticStyle: { height: "92vh", width: "100%", "z-index": "9" },
      attrs: {
        zoom: _vm.zoom,
        center: _vm.center,
        options: { preferCanvas: true }
      },
      on: {
        "update:zoom": _vm.zoomUpdated,
        "update:center": _vm.centerUpdated,
        "update:bounds": _vm.boundsUpdated
      }
    },
    [
      _c("l-tile-layer", { attrs: { url: _vm.url } }),
      _vm._v(" "),
      _c("l-control", { attrs: { position: "bottomright" } }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.showSessions,
              expression: "showSessions"
            }
          ],
          attrs: { type: "checkbox", id: "show-sessions" },
          domProps: {
            checked: Array.isArray(_vm.showSessions)
              ? _vm._i(_vm.showSessions, null) > -1
              : _vm.showSessions
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.showSessions,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.showSessions = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.showSessions = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.showSessions = $$c
                }
              },
              _vm.toggleSessions
            ]
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "show-sessions" } }, [_vm._v(" Абоненты")]),
        _c("br"),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.showDevices,
              expression: "showDevices"
            }
          ],
          attrs: { type: "checkbox", id: "show-devices" },
          domProps: {
            checked: Array.isArray(_vm.showDevices)
              ? _vm._i(_vm.showDevices, null) > -1
              : _vm.showDevices
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.showDevices,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.showDevices = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.showDevices = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.showDevices = $$c
                }
              },
              _vm.toggleDevices
            ]
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "show-devices" } }, [
          _vm._v(" Устройства")
        ]),
        _c("br")
      ]),
      _vm._v(" "),
      _c("l-layer-group", {
        ref: "sessions",
        attrs: { visible: _vm.showSessions }
      }),
      _vm._v(" "),
      _c("l-layer-group", {
        ref: "devices",
        attrs: { visible: _vm.showDevices }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }