<template>
  <WorkerEditor :state=state />
</template>

<script>

import WorkerEditor from "worker/page/WorkerEditor.vue"

export default {
  name: "MeProfile",
  components: {WorkerEditor},
    
  data()
  {
    return {
    }
  },
  
  computed: {
    state() 
    {
      return {entity: this.$store.state.user.login}
    }
  },
}
</script>
