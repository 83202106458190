define([
  'jquery',
  'underscore',
  'darsan',
  'common',
], function($, _, darsan, common)
{
  return {
    
    load: function(login, vue)
    {
      var me = this;
      return darsan.promiseDarsanToken2().then(rec => 
        {
          const headers = {
            "Authorization": "Darsan2 " + rec.token,
          };
          
          if (rec.pretend)
          {
            headers["X-Darsan-Pretend"] = rec.pretend;
          }
          
          return $.ajax(API+"/user/permission", {headers})
          .then(permissions =>
          {
            me.permissions = permissions;
            vue.$store.commit("setPermissions", permissions)
          })
          .fail(xhr => darsan.error(xhr));
        });
    },
    
    get: function(tag)
    {
      return tag in this.permissions ? this.permissions[tag] : "";
    },
    
    can_read: function(tag)
    {
      var perm = this.get(tag);
      return perm=="ro" || perm=="rw";
    },

    can_write: function(tag)
    {
      return this.get(tag)=="rw";
    },
    
  };
});
