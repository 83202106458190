var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.ready
        ? _c("SearchTable", {
            ref: "table",
            attrs: {
              name: "monitTree",
              title: "Деревья",
              columns: _vm.columns,
              dynamic: "",
              apiTopic: "device",
              apiPath: "/monit/tree?paged=1",
              metaApiTopic: "device",
              metaApiPath: "/meta/monit/tree/search",
              rowClass: _vm.rowClass
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "device_comment",
                  fn: function({ rec }) {
                    return [
                      _vm._v("\n      " + _vm._s(rec.device_comment) + "\n    ")
                    ]
                  }
                },
                {
                  key: "tree",
                  fn: function({ rec }) {
                    return [_vm._v("\n      " + _vm._s(rec.tree) + "\n    ")]
                  }
                }
              ],
              null,
              false,
              2867906857
            )
          })
        : _c("SkelSearchTable")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }